import React from 'react';
import Tesla from '../components/cases/Tesla';

const Page = (): JSX.Element => (
  <>
    <Tesla />
  </>
);

export default Page;
