import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from "../../../layouts/Default"
import NonStretchedImage from "../../common/NonStretchedImage"
import Nav from "../common/Nav"
import TextBlock from "../common/TextBlock"
import TopSection from "../common/TopSection"
import styles from './styles.module.scss';

const imagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/teslactrl/1.jpg" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/teslactrl/2.jpg" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/teslactrl/3.jpg" }) {
      ...fluidImage
    }
    image4: file(relativePath: { eq: "cases/teslactrl/4.webp" }) {
      ...fluidImage
    }
  }
  `;

const Tesla = (): JSX.Element => {
  const data = useStaticQuery(imagesQuery);
  const deliveries = [
    'Konseptutvikling',
    'Grafisk design',
    'UX / UI',
    'Programvareutvikling',
    'Produktutvikling'];

  return (
    <Layout className={styles.container}>
      <div className={styles.topWrapper}>
        <TopSection project={"Tesla CTRL app"} deliveries={deliveries}>
          <p>
            <b>Tesla CTRL</b>  - Vi satte i gang CTRL-prosjektet av nysgjerrighet over Tesla API.
            Vi fant snart ut at det var noen hull i etterspurt funksjonalitet og hva som var tilgjengelig - dermed kom CTRL-er til liv.
          </p>
        </TopSection>
        <NonStretchedImage {...data.image1.childImageSharp} />
      </div>

      <div  className={styles.textWrapper}>
        <TextBlock header={"Om prosjektet"}  paddingTopUnset={true}>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac const tur ac, vestibulum at eros.
            Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
          </p>
          <p>
            Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
            Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue.
            Etiam porta sem malesda magna mollis euismod.
          </p>
        </TextBlock>
      </div>

      <div className={styles.imageWrapper}>
        <NonStretchedImage {...data.image2.childImageSharp}/>
      </div>

      <div className={styles.wrapperApp}>
        <NonStretchedImage {...data.image3.childImageSharp}/>
        <TextBlock wide={true} floatLeft={true}>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac const tur ac, vestibulum at eros.
          </p>
        </TextBlock>
        <NonStretchedImage {...data.image4.childImageSharp} />
      </div>


      <Nav/>
    </Layout>
  )
};

export default Tesla;

